import { Dayjs } from "dayjs";

export const drawerWidth: number = 220;
export interface weekdayObject {
  long: string;
  medium: string;
  short: string;
}

export const TAG_SEPARATOR = "###";

export const WEEKDAYS: weekdayObject[] = [
  {
    long: "Sunday",
    medium: "SUN",
    short: "S",
  },
  {
    long: "Monday",
    medium: "MON",
    short: "M",
  },
  {
    long: "Tuesday",
    medium: "TUE",
    short: "T",
  },
  {
    long: "Wednesday",
    medium: "WED",
    short: "W",
  },
  {
    long: "Thursday",
    medium: "THU",
    short: "T",
  },
  {
    long: "Friday",
    medium: "FRI",
    short: "F",
  },
  {
    long: "Saturday",
    medium: "SAT",
    short: "S",
  },
];

export interface monthObject {
  long: string;
  short: string;
  index: number;
}

export interface monthsObject {
  [index: number]: monthObject;
}

export const MONTHS: monthsObject = {
  0: {
    long: "January",
    short: "Jan",
    index: 0,
  },
  1: {
    long: "February",
    short: "Feb",
    index: 1,
  },
  2: {
    long: "March",
    short: "Mar",
    index: 2,
  },
  3: {
    long: "April",
    short: "Apr",
    index: 3,
  },
  4: {
    long: "May",
    short: "May",
    index: 4,
  },
  5: {
    long: "June",
    short: "Jun",
    index: 5,
  },
  6: {
    long: "July",
    short: "Jul",
    index: 6,
  },
  7: {
    long: "August",
    short: "Aug",
    index: 7,
  },
  8: {
    long: "September",
    short: "Sep",
    index: 8,
  },
  9: {
    long: "October",
    short: "Oct",
    index: 9,
  },
  10: {
    long: "November",
    short: "Nov",
    index: 10,
  },
  11: {
    long: "December",
    short: "Dec",
    index: 11,
  },
};

export const SCHED_DISP_TYPES: string[] = ["Day", "Week"];

export const defaultHexColors = ["#4632a8", "#ffffff"];

export const RegExConstants = {
  VALID_PHONE_REGEX: new RegExp(
    "^(\\d{3}[-]?){1,2}(\\d{4})$"
    // \+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$
  ),
  VALID_ZIP_CODE_REGEX: new RegExp("^[0-9]{5}(?:-[0-9]{4})?$"),
  VALID_SSN_REGEX: new RegExp("^[0-9]{3}-[0-9]{2}-[0-9]{4}$"),
  VALID_EIN_REGEX: new RegExp("^[1-9]{2}-[0-9]{7}$"),
  VALID_PASSWORD_REGEX:
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&_])[A-Za-z\d@$!%*#?&_]{4,}$/,
};

export const MAX_NOTE_DISPLAY_CHARS = 400;

export const BASE_URL = "http://localhost:8080/api";

export interface PagingInfoProps {
  page: number;
  pageSize: number;
  sortField?: string;
  sortDirection?: string;
}

export interface DateFilterProps {
  startDate: Dayjs | null;
  endDate: Dayjs | null;
}

export const MINOR_AGE_CUTOFF: number = 18;

export const STRIPE_PUBLIC_KEY: string =
  "pk_test_51NYzXdDoDumwkgmONBMsCOeGTElu2kO8fwNhEAIq9AjezCdhmR1crvPguEWvqqkpHFa2fmKYoCfHJwXR6bpOn8cB00EvKaVp3P";

export const MESSAGING_TAGS: Map<string, string> = new Map<string, string>([
  ["ClientName", "Client Name"],
  ["ProviderName", "Provider Name"],
  ["StartDate", "Start Date"],
  ["StartTime", "Start Time"],
  ["ProviderName", "ProviderName"],
  ["OrganizationNumber", "Organization Number"],
  ["OrganizationName", "Organization Name"],
  ["ClientPortalLink", "Client Portal Link"],
]);
