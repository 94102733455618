import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import { menuItems, menuItemsObject } from "../../layout/MenuItems";

const setMenuObject = (path: string) => {
  let defaultValue: menuItemsObject = {
    id: "/",
    title: "",
    url: "/",
    icon: "",
    // header: [],
  };
  let pathName = "/".concat(path.split("/")[1]);
  const menuObject = menuItems.find((item) => item.id === pathName);

  if (menuObject == null) {
    return defaultValue;
  } else {
    return menuObject;
  }
};

interface MenuState {
  openItem: string[];
  openComponent: string;
  drawerOpen: boolean;
  componentDrawerOpen: boolean;
  menu: menuItemsObject;
}

const initialState: MenuState = {
  openItem: [window.location.pathname],
  openComponent: "buttons",
  drawerOpen: true,
  componentDrawerOpen: true,
  menu: setMenuObject(window.location.pathname),
};

const menuSlice = createSlice({
  name: "menu",
  initialState: initialState,
  reducers: {
    activeItem(state, action) {
      state.openItem = action.payload.openItem;
      state.menu = setMenuObject(action.payload.openItem[0]);
    },
    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },
    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
  },
});

// Action creators
export const { activeItem, activeComponent, openDrawer, openComponentDrawer } =
  menuSlice.actions;
export const selectDrawerOpen = (state: RootState) => state.menu.drawerOpen;
export const selectOpenItem = (state: RootState) => state.menu.openItem;
export default menuSlice.reducer;
