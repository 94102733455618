import internalConnection from "../internalConnection";
import { Number, String, Record, Static, Literal, Union } from "runtypes";

export const PreLoginDetailsRecord = Record({
  username: String.optional(),
  email: String.optional(),
  option: Union(Literal("LOGIN"), Literal("PASSWORD_RESET"))
});

export type PreLoginDetailsType = Static<typeof PreLoginDetailsRecord>;

export interface LoginDetailsObject {
  username: string;
  signedPasswordHash: string;
}

export interface LoginTrackingMetaData {
  username: string|undefined;
  lastInteraction: Date|undefined;
  expectedTimeout: Date|undefined;
  defaultTimeout: number|undefined;
}

export const PreLoginResultsRecord = Record({
  userPasswordSalt: String,
  signature: String
});
export type PreLoginResultsType = Static<typeof PreLoginResultsRecord>;

export const LoginResultsRecord = Record({
  fullname: String,
  sessionTimeout: Number.optional(),
  userId: Number,
  username: String
});
export type LoginResults = Static<typeof LoginResultsRecord>;

export const ResetPwIdCheckGetRecord = Record({
  uuid: String,
  signature: String
});

export type ResetPwIdCheckGetType = Static<typeof ResetPwIdCheckGetRecord>;

export const ResetPwPostRecord = Record({
  uuid: String,
  signature: String,
  salt: String.optional(),
  saltedPw: String.optional(),
  action: Union(Literal("GET_SALT"),Literal("SET_PASSWORD"))
});

export type ResetPwPostType = Static<typeof ResetPwPostRecord>;

export const UpdatePwPostRecord = Record({
  signedCurrentPassword: String,
  currentPassowrdSignature: String,
  saltedNewPassword: String,
  newPasswordSalt: String
});

export type UpdatePwPostType = Static<typeof UpdatePwPostRecord>;

export const updatePassword = async (updatePwObject: UpdatePwPostType) => {
  const update = await internalConnection.post("auth/update-password", updatePwObject);
  return {
    success: (update.status === 200),
    errorMessage: `${update.data.message}`
  }
};

export function getUpdatedDateTime(options: {milliseconds: number, date?: Date}): Date {
  const thisDate = (options.date) ? options.date : new Date();
  const updatedDateMs = thisDate.getTime() + options.milliseconds;
  const updatedDate = new Date(updatedDateMs);
  return updatedDate;
}

export const preloginDetails = async (preloginDetails: PreLoginDetailsType) => {
  const prelogin = await internalConnection.post("auth/prelogin", preloginDetails);
  const results = prelogin.data.results as PreLoginResultsType;
  return results;
};

export const loginDetails = async (loginDetails: LoginDetailsObject) => {
  const login = await internalConnection.post("auth/login", loginDetails);
  const results = login.data.results as LoginResults;
  const expectedTimeout = getUpdatedDateTime({milliseconds: results.sessionTimeout ? results.sessionTimeout : 9999999999});
  window.__RPSC_FRONTEND_LOGINMETA = {
    lastInteraction: new Date(),
    username: results.username,
    defaultTimeout: results.sessionTimeout,
    expectedTimeout: expectedTimeout
  } as LoginTrackingMetaData;
  return login;
};

export const resetPwIdCheck = async (uuid: string, signature: string) => {
  const checkSignature = await internalConnection.get("auth/reset-password-id-check", {
    params: {
      uuid: uuid,
      signature: signature
    }
  });
  // Return true or false
  return (checkSignature.status !== 200);
}

export const resetPwPost = async (options: ResetPwPostType) => {
  const response = await internalConnection.post("auth/reset-password", options);

  return response.data;
}

export const checkAuth = async () => {
  const login = await internalConnection.get("auth/check");
  const results = login.data.results as LoginResults;
  const expectedTimeout = getUpdatedDateTime({milliseconds: results.sessionTimeout ? results.sessionTimeout : 9999999999});
  window.__RPSC_FRONTEND_LOGINMETA = {
    lastInteraction: new Date(),
    username: results.username,
    defaultTimeout: results.sessionTimeout,
    expectedTimeout: expectedTimeout
  } as LoginTrackingMetaData;
  return login;
};

export const logout = async () => {
  const logout = await internalConnection.get("auth/logout");
  window.__RPSC_FRONTEND_LOGINMETA = undefined;
  return logout;
};

export const loginDetailsKeys = ["auth/login", "auth/check"];
