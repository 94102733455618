import { UserPermissionDetails } from "../apis/internalDb/staff/permissions";

export interface menuProps {
  id: string;
  title: string;
  url: string;
  icon?: string;
  breadcrumbs?: boolean;
}

export interface menuItemsObject {
  id: string;
  title: string;
  url: string;
  icon: string;
  notifications?: number;
}

export const menuItems: menuItemsObject[] = [
  {
    id: "",
    title: "Home",
    url: "/",
    icon: "HomeIcon",
  },
  {
    id: "admin",
    title: "Admin",
    url: "/admin",
    icon: "AdminPanelSettingsIcon",
  },
  {
    id: "clients",
    title: "Clients",
    url: "/clients",
    icon: "GroupIcon",
  },
  {
    id: "billing",
    title: "Billing",
    url: "/billing",
    icon: "PaidIcon",
  },
  {
    id: "schedule",
    title: "Schedule",
    url: "/schedule",
    icon: "CalendarMonthIcon",
  },
  {
    id: "staff",
    title: "Staff",
    url: "/staff",
    icon: "ManageAccountsIcon",
  },
  {
    id: "organization",
    title: "Organization",
    url: "/organization",
    icon: "HomeWorkIcon",
  },
  {
    id: "chat",
    title: "Chat",
    url: "/chat",
    icon: "ChatBubbleIcon",
    notifications: 6,
  },
];

export function getUserMenuItems(userPermissions: UserPermissionDetails) {
  let userMenuItems: menuItemsObject[] = [menuItems[0]];
  if (userPermissions.is_admin) {
    userMenuItems.push(menuItems[1]);
  }
  if (userPermissions.can_see_own_client_list) {
    userMenuItems.push(menuItems[2]);
  }
  if (userPermissions.is_biller) {
    userMenuItems.push(menuItems[3]);
  }
  if (userPermissions.can_view_assigned_appts) {
    userMenuItems.push(menuItems[4]);
  }
  if (userPermissions.can_add_staff) {
    userMenuItems.push(menuItems[5]);
  }
  if (userPermissions.can_update_org_info) {
    userMenuItems.push(menuItems[6]);
  }
  userMenuItems.push(menuItems[7]);
  return userMenuItems;
}

export default menuItems;
