import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
  AnyAction,
} from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import menuReducer from "./slices/menu";
import calendarReducer from "./slices/calendar";
import permissionsReducer from "./slices/permissions";
import orgReducer from "./slices/organization";
import thunk from "redux-thunk";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    menu: menuReducer,
    calendar: calendarReducer,
    permissions: permissionsReducer,
    org: orgReducer,
  },
  middleware: [thunk] as const,
});

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
