import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";
import { checkAuth, logout, resetPwIdCheck } from "../../apis/internalDb/auth/login";
import { defaultNullUndefined } from "../../shared/globalFunctions";

export interface AuthState {
  status: string | undefined;
  error: string | undefined;
  userId: string;
  isSignedIn: boolean;
  sessionTimeout: number;
  user: any | undefined;
}

const initialState: AuthState = {
  status: "idle",
  error: "",
  userId: "",
  isSignedIn: false,
  user: {},
  sessionTimeout: -1,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    // add your non-async reducers here
    signIn: (state, action: PayloadAction<AuthState>) => {
      state.userId = action.payload.userId;
      state.sessionTimeout = action.payload.sessionTimeout;
      state.isSignedIn = true;
      state.status = action.payload.status;
      state.user = action.payload.user;
    },
    signOut: (state) => {
      state.userId = "";
      state.sessionTimeout = -1;
      state.isSignedIn = false;
      state.status = "idle";
      state.user = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCheckAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCheckAuth.fulfilled, (state, action) => {
        state = action.payload;
      })
      .addCase(getCheckAuth.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export interface ResetPwState {
  status: string | undefined;
  error?: string;
  isValidSignature?: boolean;
}

const initialResetPwState: ResetPwState = {
  status: "idle"
};

const resetPwSlice = createSlice({
  name: "reset-password",
  initialState: initialResetPwState,
  reducers: {
    // add your non-async reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(getResetPwIdCheck.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getResetPwIdCheck.fulfilled, (state, action) => {
        state.isValidSignature = action.payload;
      })
      .addCase(getResetPwIdCheck.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

/* --- API Requests --- */
export const getResetPwIdCheck = createAsyncThunk(
  "reset-password/id-check",
  async ({uuid, signature}: {uuid: string, signature: string}) => {
    // const {uuid, signature} = arg;
    const response = await resetPwIdCheck(uuid, signature);
    return response as boolean;
  }
);

export const getCheckAuth = createAsyncThunk("auth/check", async () => {
  const response = await checkAuth();
  const object: AuthState = {
    userId: defaultNullUndefined(response.data.results.userId, "").toString(),
    isSignedIn: defaultNullUndefined(response.data.results.userId, "") !== "",
    sessionTimeout: defaultNullUndefined(
      response.data.results.sessionTimeout,
      ""
    ),
    user: response.data.results,
    status: "idle",
    error: undefined,
  };
  return object as AuthState;
});

export const getAuthLogout = createAsyncThunk("auth/logout", async () => {
  await logout();
  const object: AuthState = {
    userId: "",
    isSignedIn: false,
    sessionTimeout: -1,
    user: {},
    status: "idle",
    error: undefined,
  };
  return object as AuthState;
});

// Action creators
export const { signIn, signOut } = authSlice.actions;
export const selectUserId = (state: RootState) => state.auth.userId;
export const selectUserData = (state: RootState) => state.auth.user;
export const pwResetSlice = resetPwSlice.reducer;
export default authSlice.reducer;
