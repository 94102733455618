/* --- Set dotenv to override system env vars --- */
// const NodePolyfillPlugin = require("node-polyfill-webpack-plugin");
// require('dotenv').config({ override: true });

import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { LoginTrackingMetaData } from "./apis/internalDb/auth/login";
const App = lazy(
  () => import("./App")
);

declare global {
  interface Window {
    __RPSC_FRONTEND_LOGINMETA?: LoginTrackingMetaData
  }
}

const staleTime = 1000 * 60 * 5;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      refetchOnReconnect: true,
      retry: false,
      staleTime: staleTime,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <Suspense><App /></Suspense>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
);
