import { createSlice } from "@reduxjs/toolkit";
import type { RootState } from "../store";
import type { PayloadAction } from "@reduxjs/toolkit";

interface AvailableOrg {
  orgId: number;
  orgName: string;
  orgStripeId: string;
}

const emptyOrg: AvailableOrg = {
  orgId: 0,
  orgName: "",
  orgStripeId: "",
};

interface OrganizationState {
  activeOrg: AvailableOrg;
  availableOrgs: AvailableOrg[];
}

const initialState: OrganizationState = {
  activeOrg: emptyOrg,
  availableOrgs: [],
};

const orgSlice = createSlice({
  name: "org",
  initialState: initialState,
  reducers: {
    // add your non-async reducers here
    setActiveOrg: (state, action: PayloadAction<AvailableOrg>) => {
      state.activeOrg = action.payload;
      localStorage.setItem("orgId", action.payload.orgId.toString());
    },
    setAvailableOrgs: (state, action: PayloadAction<AvailableOrg[]>) => {
      state.availableOrgs = action.payload;
    },
  },
});

// Action creators
export const { setActiveOrg, setAvailableOrgs } = orgSlice.actions;

export const selectCurrentOrg = (state: RootState) => state.org.activeOrg;
export const selectAvailableOrgs = (state: RootState) =>
  state.org.availableOrgs;
export default orgSlice.reducer;
