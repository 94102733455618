import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import { useAppDispatch } from "../hooks";
import type { RootState } from "../store";
import {
  getUserDetails,
  UserDetailsListType,
} from "../../apis/internalDb/staff/staff";
import { UserPermissionDetails } from "../../apis/internalDb/staff/permissions";

interface PermissionsState {
  availableStaff: UserDetailsListType[];
  supervisees: number[];
  selectedStaff: number[];
  userPermissions: UserPermissionDetails | null;
  status: string;
  error?: string;
}

const initialState: PermissionsState = {
  availableStaff: [],
  supervisees: [],
  selectedStaff: [],
  userPermissions: null,
  status: "idle", //idle, loading, succeeded, failed
  error: "",
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState: initialState,
  reducers: {
    setSelectedStaff: (state, action: PayloadAction<number[]>) => {
      state.selectedStaff = action.payload;
    },
    setAvailableStaff: (
      state,
      action: PayloadAction<UserDetailsListType[]>
    ) => {
      state.availableStaff = action.payload;
      state.selectedStaff = action.payload.map((s) => s.user_id);
    },
    setSupervisees: (state, action: PayloadAction<number[]>) => {
      state.supervisees = action.payload;
    },
    setUserPermissions: (
      state,
      action: PayloadAction<UserPermissionDetails>
    ) => {
      state.userPermissions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableStaff.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getAvailableStaff.fulfilled, (state, action) => {
        state.availableStaff = action.payload;
        state.selectedStaff = action.payload.map((p) => p.user_id);
        state.status = "idle";
      })
      .addCase(getAvailableStaff.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAvailableStaff = createAsyncThunk(
  "permissions/getAvailableStaff",
  async () => {
    const staffData = await getUserDetails("active", "");
    return staffData as UserDetailsListType[];
  }
);

// Action creators
export const {
  setSelectedStaff,
  setAvailableStaff,
  setUserPermissions,
  setSupervisees,
} = permissionsSlice.actions;
export const selectSelectedStaff = (state: RootState) =>
  state.permissions.selectedStaff;
export const selectAvailableStaff = (state: RootState) =>
  state.permissions.availableStaff;
export const selectSupervisees = (state: RootState) =>
  state.permissions.supervisees;

export const selectUserPermissions = (state: RootState) =>
  state.permissions.userPermissions;
export default permissionsSlice.reducer;
